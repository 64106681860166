@import '../../../../../../variables.scss';
@import '../../../../../../components/Pipe/animations.scss';

.container {
  --water-color: #{$pureWaterColor};
}

@include for-tablet-portrait-down {
  .container {
    position: relative;
    display: flex;
    justify-content: flex-start;
    label {
      font-size: 0.75em;
      cursor: pointer;
    }

    .reverseOsmosisSystemContainer {
      display: flex;
      position: relative;
      flex: 1 1 100%;
      justify-content: center;
      align-items: center;
      left: -5em;
      margin-top: -0.125em;

      .skidContainer,
      .switchContainer {
        display: flex;
        flex: 1 1 100%;
      }

      .skidContainer {
        position: relative;
        align-items: center;
        justify-content: flex-start;
      }

      .waterFlowSensor {
        position: relative;
        top: -1.7em;
        left: 2em;
      }

      .waterFlowSensorStopped {
        position: relative;
        top: -1.7em;
        left: 2em;
        width: 2.44em;
        height: 2.44em;
      }

      .switchContainer {
        position: relative;
        left: 1.25em;
        order: 3;
        label {
          text-align: center;
        }

        & > div {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-left: 0.5em;
        }
      }

      .skid {
        width: 5em;
      }

      .skidOutPipe {
        position: relative;
        top: -1.7em;
        margin-left: 2em;
        width: 3.5em;
        @include pipe-flows-left();
      }

      &.perZoneValveMode {
        $skidOutPipeWidth: 7.75em;

        .skidContainer {
          left: ($skidOutPipeWidth / 2) - 6.75em;
        }

        .skidOutPipe {
          width: $skidOutPipeWidth;
        }
      }
    }
  }
}

@include for-tablet-landscape-up {
  .container {
    display: flex;
    flex-direction: column;
    width: 100%;

    label {
      font-size: 0.75em;
      cursor: pointer;
    }
  }

  .reverseOsmosisSystemContainer {
    display: flex;
    position: relative;
    flex: 1 1 100%;
    justify-content: center;
    align-items: center;
    right: 1.5em;

    .skidContainer,
    .switchContainer {
      display: flex;
      flex: 1 1 50%;
    }

    .skidContainer {
      position: relative;
      align-items: center;
      justify-content: flex-start;
    }

    .waterFlowSensor {
      position: absolute;
      top: -0.5em;
      left: -1.25em;
    }

    .waterFlowSensorStopped {
      position: absolute;
      top: -0.5em;
      left: -1.25em;
      width: 2.44em;
      height: 2.44em;
    }

    .switchContainer {
      justify-content: flex-end;
      padding-right: 3em;
      label {
        text-align: center;
      }

      & > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 1em;
      }
    }

    .skid {
      width: 5em;
    }

    .skidOutPipe {
      position: relative;
      top: -1.7em;
      margin-left: 1.2em;
      width: 2.2em;
      @include pipe-flows-left();
    }
  }
}
