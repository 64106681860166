@import '../../variables.scss';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;

  &:after {
    content: '';
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-image: url('../../assets/images/login-bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top left;
    z-index: -2;
  }
}

.formContainer {
  display: flex;
  flex-direction: row;
  padding: 3em;
  border-radius: 2em;
  background-color: $appBackgroundColor;
}

.logo {
  width: 17em;
  justify-self: center;
  align-self: center;
  margin-right: 2.4em;
}

.form {
  position: relative;
  border-left: 1px solid $notActiveLowContrastColor;
  padding-left: 3em;
  width: 20em;
}

.kalera {
  width: 12em;
  margin-top: 3em;
  margin-bottom: -6em;
  path {
    fill: white !important;
  }
}

@include for-phone-only {
  .formContainer {
    display: flex;
    flex-direction: column;
    padding: 2em 3em 2.75em 3em;
  }

  .logo {
    width: 15em;
    margin: 0 1em 1em 0;
  }

  .form {
    border: none;
    padding: 0;
  }

  .kalera {
    width: 8em;
    margin-top: 3em;
    margin-bottom: -4em;
  }
}
