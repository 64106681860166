@import '../../variables.scss';

.errorAlert {
  margin-top: 0.75em;
}

.buttonGroup {
  margin-top: 1.5em;
  display: flex;
  flex-direction: column;
  align-items: center;

  .forgotPassword {
    text-align: center;
    font-size: 0.9em;
  }

  button {
    position: relative;
    text-transform: uppercase;
  }

  & > * {
    width: 100%;
    &:not(:last-child) {
      margin-bottom: 1.5em;
    }
  }
}

.loadingModal {
  background-color: rgb(43, 43, 43);
  position: absolute;
  height: 5em;
  width: 5em;
  border-radius: 5em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.loadingModal > div {
  transform: translate(-0.1rem, 0.1rem);
}
