@import '../../variables.scss';

.stackStatus {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.stackLight {
  width: 100%;
  margin-top: -5%;
  align-self: flex-start;
  flex: 0 0 0;

  > img {
    width: 100%;
  }
}

.stackIrrigation {
  width: 75%;
  margin-top: -5%;
  flex: 0 0 0;

  > img {
    width: 100%;
  }
}

.stackErrors {
  flex: 1 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 75%;
  margin-top: -5%;
  margin-bottom: 5%;

  > * {
    flex: auto;
    height: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 40%;
  }

  img {
    width: 100%;
    height: 100%;
  }

  .harvestIndicator {
    position: relative;

    .harvestScheduleInfoText {
      position: absolute;
      background: $appBackgroundColor;
      text-align: center;
      font-size: 0.75em;
      width: 10em;
      left: -100%;
      padding: 1em;
      border-radius: 0.5em;
      text-transform: uppercase;
      font-weight: bold;
      @include drop-shadow();

      &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: -1.25em;
        display: flex;
        align-items: center;
        margin: auto 0;

        width: 0;
        height: 0;
        $arrow-length: 1.25em;
        $arrow-height: 0.75em;
        border-top: $arrow-height solid transparent;
        border-bottom: $arrow-height solid transparent;
        border-left: $arrow-length solid $appBackgroundColor;
      }
    }
  }

  .waterLeakIcon {
    margin: 10%;
  }

  .pumpTimeoutIcon {
    margin: 5%;
  }

  .valveFailedIcon {
    margin: 5%;
  }

  .valveTestIcon {
    color: $appTextColor;
    width: auto;
    margin: 5%;
    width: 100%;
    height: 100%;
  }

  .cleanInPlaceIcon {
    color: $appTextColor;
    width: auto;
    width: 100%;
    height: 100%;
  }

  .harvestAlert {
    border-style: solid;
    animation: needsAttentionPulse 500ms infinite;
  }
}

@keyframes needsAttentionPulse {
  0% {
    border-color: $componentAutomaticOffColor;
  }
  50% {
    border-color: $dangerColor;
  }
  100% {
    border-color: $componentAutomaticOffColor;
  }
}

.ht {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  bottom: 0;
  margin-bottom: -1.3em;
  margin-left: -0.25em;
  overflow-x: auto;
  scroll-behavior: smooth;
}

.ht_c {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  bottom: -25px;
  margin-left: -0.29em;
  overflow-x: auto;
  scroll-behavior: smooth;
}

.htClearfix {
  content: '&nbsp;';
  display: block;
  height: 0.5em;
  width: 1em;
}

.szBlip {
  margin-top: 0.2em;
  margin-right: 0.1em;
  display: inline-block;
  height: 1.25em;
  width: 1.25em;
  border: 0.1em solid rgba(255, 255, 255, 0.6);
  border-radius: 1.5em;
  color: rgba(255, 255, 255, 0.6);
  text-shadow: 0 0 5px #888888;
  transition-duration: 0.2s;
}

.szBlip:hover {
  margin-bottom: 0.1em;
  margin-top: 0.1em;
  color: rgba(255, 255, 255, 1);
  border: 0.1em solid rgba(255, 255, 255, 1);
  text-shadow: 0 0 7px #000000;
}

.szBlip > span {
  position: absolute;
  margin-left: 0.4em;
  margin-top: 0.5em;
  height: 1em;
  width: 1em;
  font-size: 70%;
  font-weight: 700;
  line-height: 0.5em;
  white-space: nowrap;
}
