@import '../../../../../variables.scss';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sectionTitle {
  font-size: 0.9em;
  text-transform: uppercase;
  font-weight: bold;
}

.heading {
  background-color: $navigationBackgroundColor;
  border-bottom: 1px solid $notActiveLowContrastColor;
  width: 100%;
  margin-bottom: 0;
  padding: 0.6em 1.2rem;
  display: flex;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0;
  }

  .refresh {
    margin-left: auto;
    cursor: pointer;
  }
}

.filterContainer {
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 1em 1.5em;
  border-bottom: 1px solid $notActiveLowContrastColor;

  .searchInput {
    margin-bottom: 0.5em;

    ::placeholder {
      color: #495057;
      opacity: 1;
    }

    ::-ms-input-placeholder {
      color: #495057;
    }

    input {
      padding-left: 1em;
      color: hsl(0, 0, 20%);
      border: 1px solid hsl(0, 0%, 80%);
      border-radius: 4px;
    }
  }

  .dateInputsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 0.5em;

    & > *:not(:last-child) {
      margin-right: 0.5em;
    }

    .dateContainer {
      display: flex;
      flex-direction: column;
      justify-content: left;
      width: 100%;
    }

    .dateInput {
      height: calc(1.5em + 0.75rem + 2px);
      width: 100%;
      border-radius: 4px;
      border: 1px solid hsl(0, 0%, 80%);
      color: hsl(0, 0, 20%);
      padding: 0 0 0 1em;

      &:disabled {
        border: 1px solid #555555;
        background-color: #222;
        color: hsl(0, 0, 40%);
      }
    }
  }

  .userSelect {
    width: 100%;
  }

  .checkboxContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    label {
      margin: 0 0.5em;
    }
  }
}

.activityItem {
  width: 100%;
  padding: 2em;
  &:not(:last-child) {
    border-bottom: 1px solid $notActiveLowContrastColor;
  }
}

.userName,
.locationName {
  font-weight: bold;
}

.timestamp {
  font-style: italic;
  margin-top: 0.5em;
}

.moreButton {
  width: 100%;
}

.loadingIcon {
  position: relative !important;
  top: 45px;
}

@include for-phone-only {
  .heading {
    font-size: 1.5em;
  }
}
