@import '../../../variables.scss';

.container {
  position: relative;
}

.facilitySelectorModal {
  max-width: 25em;

  &:global(.modal-dialog) {
    height: auto;
    margin-top: 7em;
  }
}

.facilityItem {
  font-size: 1.5em;
  text-transform: uppercase;
  &:last-child {
    margin-bottom: 0;
  }

  .facilityIcon {
    margin-right: 0.35em;
  }
}
