@import '../../variables.scss';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @include panel();
}

.closeButton {
  top: 0;
  z-index: 1;
}

.lightScheduleContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1.71em;
  position: relative;
}

.levelStatusContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0.75em;
}

.statusContainer {
  margin-bottom: 1.71em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stackStatus {
  width: 10.586em;
  height: 32.571em;
}

@include for-tablet-portrait-down {
  .lightScheduleContainer {
    flex-direction: column;
    align-items: center;
  }
  .lightRecipe {
    margin-top: 1.5em;
  }

  .stackStatus {
    margin-bottom: -1.2em;
    height: 20em;
  }

  .stackTank {
    display: none;
  }
}

@include for-tablet-landscape-up {
  .container {
    max-width: 77.357em;
  }

  .lightScheduleContainer {
    margin-bottom: 2.28em;
  }

  .levelsContainer {
    order: 3;
  }

  .levelStatusContainer {
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
  }

  .lightRecipe {
    position: absolute;
    left: 0;
  }

  .statusContainer {
    margin: 0 3.71em;
    order: 2;
  }

  .stackStatus {
    height: 36.317em;
  }
}
