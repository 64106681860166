@import '../../variables.scss';

$borderRadius: 0.25em;

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 1em;
}

.tankContainer {
  display: flex;
  flex-direction: row;
}

.tank {
  position: relative;
  padding-top: 100%;
  width: 100%;
  border-radius: $borderRadius;
}

.volumeValue {
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 1.25em;
  color: $buttonTextColor;
}

.volumeUnit {
  position: absolute;
  font-size: 0.75em;
  top: 5%;
  right: 10%;
  color: $buttonTextColor;
  z-index: 2;
}

.invalidWaterSensorStateIcon {
  position: absolute;
  top: 0;
  left: 5%;
  width: 2.5em;
  height: 2.5em;
}

.tankInner {
  position: absolute;
  background-color: $pipeColor;
  width: auto;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  border-radius: $borderRadius;

  .dryTank {
    --water-color: #{$warningColor};
  }
}

.levelSensorsContainer {
  position: absolute;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  opacity: 1;

  &.onRight {
    right: -1.85em;
    padding-left: 0.5em;
    padding-right: 0;
  }

  &.onLeft {
    left: -1.85em;
    padding-right: 0.5em;
    padding-left: 0;
  }
}

.tankWaves {
  position: relative;
  top: 2%;
  height: 7%;
  mask: url(../../assets/images/wave.png);
  background-color: var(--water-color);
  mask-position: 0 0;
  mask-repeat: repeat-x;
  mask-size: 33% 100%;
}

.dryAlertIcon {
  position: absolute;
  width: 45%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 0;
  animation: needsAttentionPulse 500ms infinite;
}

.pumpOn {
  .tankWaves {
    animation: wave-animation 60s linear 0s infinite;
    mask-size: 25% 110%;

    @keyframes wave-animation {
      to {
        mask-position: 2050% 0;
      }
    }
  }
}

.tankLevel {
  background-color: var(--water-color);
  width: 100%;
  height: 40%;
  transition: height 1s ease-out;
  border-bottom-left-radius: $borderRadius;
  border-bottom-right-radius: $borderRadius;
}

.error {
  .tank {
    --water-color: #{$dangerColor};
  }
}

.pump {
  position: absolute;
  width: 30%;
  height: 30%;
  right: 4%;
  bottom: 4%;
  z-index: 1;
}

.level {
  top: 0;
}

.children {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@keyframes needsAttentionPulse {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
