@import '../../variables.scss';

.passwordStrength {
  margin: 1em 0 1.5em 0;
  text-align: center;
  text-transform: uppercase;
  border-radius: 0.75em;
  font-weight: bold;
  &:global(.weak) {
    color: #ffffff99 !important;
    background: linear-gradient(to right, $dangerColor 25%, $notActiveColor 0);
  }
  &:global(.okay) {
    color: #ffffffaa !important;
    background: linear-gradient(to right, $warningColor 50%, $notActiveColor 0);
  }
  &:global(.good) {
    color: #000000aa !important;
    background: linear-gradient(to right, $successColor 75%, $notActiveColor 0);
  }
  &:global(.strong) {
    color: #000000aa !important;
    background: linear-gradient(
      to right,
      $successColor 100%,
      $notActiveColor 0
    );
  }
}
