@import '../../variables.scss';

.title {
  width: 100%;
}

.initiatePasswordResetSucceeded {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 3em;
}

.formComplete {
  visibility: hidden;
}

.alert {
  margin-top: 0.75em;
}

.buttonGroup {
  margin-top: 1.5em;
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    position: relative;
    text-transform: uppercase;
  }

  & > * {
    width: 100%;
    &:not(:last-child) {
      margin-bottom: 1.5em;
    }
  }
}

@include for-phone-only {
  .title {
    text-align: center;
  }

  .initiatePasswordResetSucceeded {
    margin-left: 0;
  }
}
