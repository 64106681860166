@import '../../variables.scss';

.modal {
  max-width: 80vw;
  display: flex;
  justify-content: center;

  @include for-phone-only {
    max-width: 100vw;
    padding: 0;
  }

  :global(.modal-body) {
    background-color: $appBackgroundColor;
    padding: 0.5em 1em 0.5em 1em;
  }
}

.stackControlsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0.5em;
  padding: 0.75em 1em 0.75em 1em;
  border-radius: 0.5em;
  background-color: $disabledFormControlBackgroundColor;
  @include drop-shadow();
}

.modalContentContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 69vh;

  @include for-phone-only {
    max-height: 60vh;
  }

  .loading & {
    visibility: hidden;
  }
}

.light {
  width: 10em;
  margin-top: 2em;
  cursor: pointer;
}
