@import '../../variables.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $lightBulbOnColor;
  position: relative;
  width: 100%;
}

.showLightScheduleButton {
  display: none;
}

.showLightScheduleIcon {
  display: none;
  cursor: pointer;
  margin-bottom: 0;
}

.lightOnIcon {
  color: $lightBulbOnColor;

  transition: color 0.25s;
}

.lightOffIcon {
  color: $dangerColor;

  transition: color 0.25s;
}

.photoperiodPopover {
  position: absolute;
  background: $appBackgroundColor;
  text-align: center;
  font-size: 0.45em;
  font-weight: bold;
  width: 8em;
  left: -10em;
  padding: 0.75em;
  border-radius: 0.5em;
  text-transform: uppercase;
  @include drop-shadow();

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: -1.25em;
    display: flex;
    align-items: center;
    margin: auto 0;

    width: 0;
    height: 0;
    $arrow-length: 1.25em;
    $arrow-height: 0.75em;
    border-top: $arrow-height solid transparent;
    border-bottom: $arrow-height solid transparent;
    border-left: $arrow-length solid $appBackgroundColor;
  }
}

.title {
  position: relative;
  font-size: 1.547em;
  margin-bottom: 1.14em;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -0.25em;
}

.switchLabel {
  margin: 0;
}

.switch {
  margin-top: 0.081em;
}

.disabled {
  opacity: 0.3;
}

.currentTime {
  position: absolute;
  top: -0.57em;
  z-index: 10;
  pointer-events: none;
  display: none;
}

.timeBlocks {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: space-evenly;
}

.activeTimeBlockIndicator {
  position: absolute;
  top: 0.081em;
  bottom: 0;
  border-left: 0.081em dashed white;
  opacity: 0.95;

  &::before {
    position: absolute;
    top: -0.489em;
    content: '';
    left: -0.5em;
    width: 0;
    height: 0;
    border-left: 0.489em solid transparent;
    border-right: 0.489em solid transparent;

    border-top: 0.489em solid white;
  }
}

.timeBlock {
  height: 2.28em;
  flex: 1 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  &:first-child {
    border-top-left-radius: 0.2407em;
    border-bottom-left-radius: 0.2407em;
    padding-left: 0.244em;
  }

  &:last-child {
    border-top-right-radius: 0.2407em;
    border-bottom-right-radius: 0.2407em;
    padding-right: 0.244em;
  }

  .time {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .hour {
      font-size: 0.75em;
    }

    .minute {
      font-size: 0.45em;
      margin-right: 0.1em;
    }

    &.inTheHour {
      .hour {
        visibility: hidden;
      }
    }
  }

  &.timeBlockOn {
    background-color: $lightBulbOnColor;
    opacity: 0.9;

    .time {
      color: $appBackgroundColor;
    }
  }

  &.timeBlockOff {
    background: $notActiveColor;

    .time {
      color: $lightBulbOnColor;
      opacity: 0.75;
    }
  }
}

@include for-tablet-portrait-down {
  .title {
    margin-bottom: 0;
  }

  .timeBlocks {
    flex-wrap: wrap;
    width: 24.429em;
    border: none;
    max-height: 0;
    transition: max-height 0.2s ease-out;
    overflow: hidden;
    border-radius: 0;
  }

  .collapsibleTimeBlocks .showLightScheduleIcon {
    display: initial;
    color: $buttonTextColor;
    margin-left: 0.35em;
    transition: transform 0.2s ease-in-out;
  }

  .showLightScheduleButton {
    &:checked,
    .nonCollapsibleTimeBlocks & {
      ~ .timeBlocks {
        max-height: 32.571em;
      }

      ~ .title .showLightScheduleIcon {
        transform: rotate(180deg);
      }
    }
  }

  .timeBlock {
    flex: 0 0 4.071em;
    border-radius: 0 !important;
    border: 0.081em solid $notActiveLowContrastColor;
    margin-top: 1.14em;

    .time {
      flex-direction: row;
      font-size: 1em;

      .hour {
        font-size: 0.85em;
        height: initial;
      }

      .minute {
        font-size: 0.85em;
      }

      &.inTheHour {
        .hour {
          visibility: unset;
        }
      }
    }
  }
}
