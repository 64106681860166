@import '../../variables.scss';

.modal {
  min-width: 90vw;

  .modalBody {
    width: 100%;
    height: 75vh;
  }

  .charts {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    height: 100%;
    > * {
      margin-bottom: 1.5em;
    }

    .gaugeChartContainer {
      flex: 1;
      display: flex;

      .gauge {
        font-size: 0.75vmin;

        @include for-phone-only {
          display: none;
        }
      }
    }
  }
}
