@import '../../variables.scss';

.container {
  --water-color: #{$dirtyWaterColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.invalidWaterSensorStateIcon {
  position: absolute;
  top: 0;
  left: 3%;
  width: 2.5em;
  height: 2.5em;
}

.pumpModeSwitchContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.pumpModeSwitchLabel {
  font-size: 0.75em;
  margin: 0 0.75em 0 0;
}

.flushTankButton {
  position: absolute;
  right: 4%;
  bottom: 4%;
  z-index: 1;
  width: 3em;
  height: 3em;
  padding: 0;

  &.manual.on button {
    border: 0.1em solid
      adjust-color($color: $componentManualOnColor, $lightness: 5%) !important;
  }

  &.manual.off button {
    border: 0.1em solid
      adjust-color($color: $componentManualOffColor, $lightness: 5%) !important;
  }

  &.automatic.on button {
    border: 0.1em solid
      adjust-color($color: $componentAutomaticOnColor, $lightness: 5%) !important;
  }

  &.automatic.off button {
    border: 0.1em solid
      adjust-color($color: $componentAutomaticOffColor, $lightness: 5%) !important;
  }
}

.tank {
  position: relative;
  width: 100%;
  font-size: 1.25em;
}

.zoneReturnLabel {
  font-size: 1.125em;
  display: inline-block;
}
