@import '../../variables.scss';

.modal {
  max-width: 77em;
  display: flex;
  justify-content: center;

  :global(.modal-body) {
    background-color: $appBackgroundColor;
  }

  .pumpConfigurationsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0.5em 0;
    margin: -1em 0;

    @include for-phone-only {
      max-height: 60vh;
    }

    @include for-tablet-landscape-up {
      min-height: 45em;
      max-height: 69vh;
    }

    .pumpConfigurationContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0.5em;
      padding: 0.75em 1em 0.25em 1em;
      width: 16em;
      background: $disabledFormControlBackgroundColor;
      border-radius: 0.5em;
      @include drop-shadow();

      --pump-color: #{$componentAutomaticOnColor};

      transition: color 0.5s ease-in-out, background-color 0.5s ease-in-out;

      &.enabled {
        color: white;

        background-color: var(--pump-color);
      }

      @include for-phone-only() {
        width: 100%;
      }

      .pumpContainer {
        display: flex;
        align-items: center;
        margin-bottom: 0.75em;
        width: 100%;

        .pumpName {
          margin-right: 0.4em;
          font-size: 1.75em;
          display: flex;
          justify-content: center;
          border-radius: 50%;
          align-items: center;
          background-color: $notActiveLowContrastColor;
          width: 2em;
          height: 2em;
          color: $hoverTextColor;
        }

        select {
          flex: 1;
        }
      }

      .configurationValues {
        @include for-phone-only() {
          width: 100%;
        }

        :global(.form-group) {
          width: 100%;
          &:not(:last-child) {
            margin-bottom: 0.75em;
          }

          :global(.input-group-append) {
            :global(.input-group-text) {
              width: 4em;
              text-align: center;
              display: inline-block;
            }
          }
        }
      }
    }
  }
}
