@import '../../variables.scss';

@mixin offset-pipe-animation($offsetTime) {
  animation-delay: calc(#{$offsetTime} + var(--animation-sync-delay));
}

@mixin pipe-flows-right {
  background-image: linear-gradient(
    to right,
    var(--water-color) $dropSize,
    var(--pipe-color) $dropSize
  );
  background-size: ($dropSize * 2) $dropSize;
  animation-name: horizontal-pipe-animation;
}

@mixin pipe-flows-left {
  @include pipe-flows-right();
  transform: rotate(180deg);
}

@mixin pipe-flows-down {
  background-image: linear-gradient(
    to bottom,
    var(--pipe-color) $dropSize,
    var(--water-color) $dropSize
  );
  background-size: $dropSize ($dropSize * 2);
  animation-name: vertical-pipe-animation;
}

@mixin pipe-flows-up {
  @include pipe-flows-down();
  transform: rotate(180deg);
}

@keyframes horizontal-pipe-animation {
  to {
    background-position: ($dropSize * 8000) 0;
  }
}

@keyframes vertical-pipe-animation {
  to {
    background-position: 0 ($dropSize * 8000);
  }
}
